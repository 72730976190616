import React, { Component } from "react";
import { Container, Card, CardDeck, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../components/Loading";
import { Redirect } from "react-router-dom";
//import { useAuth0 } from "../react-auth0-spa";

class Renew extends Component {

  constructor(props) {
    super(props);

    this.state = { loading: true, data: undefined };
   // this.itemList.bind(this);
  }

  // https://www.valentinog.com/blog/await-react/
  async componentDidMount() {
    console.log(this.props.user)
    const token = await this.props.token;
   // let user_id = this.state.user.sub;
    //const { user } = useAuth0();
    //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    try {
      const response = await fetch(`https://apivisgateway-72pm3i3mfa-uc.a.run.app/user?name=` + this.props.user,
      {
        method: "GET",
        //mode: "no-cors",
        credentials: 'include',
          headers: new Headers({
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          })});
      if (!response.ok) {
       // console.log('Resp:  ', response);
        throw Error(response.statusText);
      }
      const json = await response.json();
      console.log(json.result);
      this.setState({ data: json.result, loading: false });

    } catch (error) {
      console.log(error);
    }
  }

 // <Row className="container-fluid justify-content-center">

 goToPage(link) {
  this.setState(
    {
      link: link
    }
  )
}

  render() {
    if (this.state.loading) {
      return <Loading />;
    }



    return (
      <>
      { this.state.link &&
        <Redirect to={this.state.link}/>
        }
      <Row className="justify-content-left">

      {this.state.data.map((item, i) => (


         <Col sm="5">
        {item.data.payment_plan.meta.description === 'Green Hornet' ? (

         <Card body className="text-center mb-4">
         <CardTitle><h4>{  item.data.payment_plan.meta.description}</h4></CardTitle>
           <CardText className="plan-details">
             <ul>
         <li key={item.i++}>Created at {item.data.payment_plan.customer.inserted_at}</li>

               <li>Node-red service $14/m</li>
               <li>Web-based</li>
               <li>1 Shared CPU</li>
               <li>512MB RAM</li>
               <li>5GB SDD</li>
               <li>100 GB/month* </li>
               <li>ID {item.gcfid}</li>
               <li key={i++}><a href="https://partial.ly" target="_blank">Subscription</a> {item.data.payment_plan.status}</li>
               <li>
               { "oauth" in item.data ? (
                 <p>OAuth ID: {item.data.oauth.clientid}</p>
                ) : (<>
                <p className="text-danger"> Server not deployed</p>
                <p>Configure OAuth settings for the server to be deployed</p>
                </>)}
               </li>
               </ul>
               <Button color="light" onClick={() => this.goToPage('/edit' + '/id/' + item.gcfid)}><FontAwesomeIcon icon="cog"  /> OAuth settings </Button>
             </CardText>

         </Card>



        ) : (

          <Card body className="text-center">
          <CardTitle><h4>{item.data.payment_plan.meta.description}</h4></CardTitle>
            <CardText className="plan-details">
              <ul>
          <li key={item.user_id}>Created at {item.data.payment_plan.customer.inserted_at}</li>

                <li>Node-red service $30/m</li>
                <li>Web-based</li>
                <li>1 CPU</li>
                <li>1 GB RAM</li>
                <li>10GB SDD</li>
                <li>100 GB/month* </li>
                <li>ID {item.gcfid}</li>
                <li key={i++}><a href="https://partial.ly" target="_blank">Subscription</a> {item.data.payment_plan.status}</li>
               <li>
               { "oauth" in item.data ? (
                 <p>OAuth ID: {item.data.oauth.clientid}</p>
                ) : (
                  <>
                  <p className="text-danger"> Server not deployed</p>
                  <p>Configure OAuth settings for the server to be deployed</p>
                  </>
                )}
               </li>
                </ul>
                <Button color="light" onClick={() => this.goToPage('/edit' + '/id/' + item.gcfid)}><FontAwesomeIcon icon="cog"  /> OAuth settings </Button>

              </CardText>
          </Card>

        )}
      </Col>


    )


      )}


    </Row>
    </>
    )

}

    }
export default Renew;
