import React, { useState } from "react";
import { Container, Card, CardDeck, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { Redirect,Link } from "react-router-dom";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0 } from "../react-auth0-spa";

const Renew = () => {
  const { loading, user } = useAuth0();
  const [showResult, setShowResult] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [TheLink, setTheLink] = useState("");
  const { getTokenSilently } = useAuth0();


  console.log(user);
  const goToPage = (service) => {
    let user_id = user.sub;
    if(service === "green"){
      window.open("https://partial.ly/checkout?offer=a9bc5238-ccba-4b6e-988d-d8de2bb05f27&amount=168&meta[user_id]=" + user_id +"&meta[description]=Green%20Hornet",'_blank');
    /* 

    https://partial.ly/checkout?offer=a9bc5238-ccba-4b6e-988d-d8de2bb05f27&amount=168&meta[description]=Green%20Hornet

    https://demo.partial.ly/checkout?offer=84f1bbdc-86f8-41a5-b4ea-6262b0000c8e&amount=168&meta[user_id]

    
https://partial.ly/checkout?offer=baf6c7e8-9681-49b7-bfac-6be0f9e50038&amount=360&meta[description]=Purple%20Paste



    window.location.href = "https://demo.partial.ly/checkout?offer=84f1bbdc-86f8-41a5-b4ea-6262b0000c8e&amount=168&meta[user_id]=" + user_id +"&meta[description]=Green%20Hornet";*/
/*
    setTheLink('https://demo.partial.ly/checkout?offer=84f1bbdc-86f8-41a5-b4ea-6262b0000c8e&amount=12&meta[description]=Green%20Hornet');*/
  } else{
    window.open("https://partial.ly/checkout?offer=baf6c7e8-9681-49b7-bfac-6be0f9e50038&amount=360&meta[description]=Purple%20Paste&meta[user_id]=" + user_id, "_blank");
  }
  
  
  }


  if (loading || !user) {
    return <Loading />;
  }


  return (
    <>
     
      <Container className="container-fluid justify-content-center">
        <Row className="container-fluid justify-content-center">

          <Col sm="5">
            <Card body className="text-center">
              <CardTitle><h4>Green Hornet</h4></CardTitle>
              <CardText className="plan-details">
                <h1>$14/m</h1>
                <ul><li>Node-red service</li><li>Web-based</li><li>1 Shared CPU</li><li>512MB RAM</li><li>5GB SDD</li><li>100 GB/month* </li></ul> </CardText>
              <Button onClick={() => goToPage("green")}>Renew</Button>
            </Card>
          </Col>
          <Col sm="5">
            <Card body className="text-center">
              <CardTitle><h4>Purple Super Paste</h4></CardTitle>
              <CardText className="plan-details">
                <h1>$30/m</h1>
                <ul>
                  <li>Node-red service</li>
                  <li>Web-based</li>
                  <li>1 CPU</li>
                  <li>1GB RAM</li>
                  <li>10GB SSD</li>
                  <li>100 GB/month*</li>
                </ul>

              </CardText>
              <Button onClick={() => goToPage("purple")}>Renew</Button>
            </Card>
          </Col>


        </Row>
        <small>* Network bandwidth above costs $0.20/GB</small>
      </Container>
    </>
  );
};


export default Renew;
