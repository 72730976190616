import React, { Component } from "react";
import { Container, Card, CardDeck, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input, FormText } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../components/Loading";
import { Redirect } from "react-router-dom";
import { Formik, Field, ErrorMessage } from 'formik';
import { Form as FormikForm } from 'formik';
import { Form as RSForm } from 'reactstrap';
import axios from "axios";

class GitHubForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: undefined,
      response: undefined,
      clientid: undefined,
      clientsecret: undefined,
      subdomain: undefined
    };

    /*
    this.state = {
      loading: false,
      data: undefined,
      response: undefined,
      clientid: 'theid',
      clientsecret: 'secret',
      subdomain: 'test',
      description: 'description'
     }


     */
  }
  // this.state.data.data.oauth.clientid, clientsecret: this.state.data.data.oauth.clientsecret, authorizationurl: ////this.state.data.data.oauth.authorizationurl
  // https://www.valentinog.com/blog/await-react/
  /**
   * Hämta info från GCF
   */
  async componentDidMount() {
    const token = await this.props.token;
    //console.log(this.props.user)
    // let user_id = this.state.user.sub;
    //const { user } = useAuth0();
    //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // https://rodenedgateway-72pm3i3mfa-uc.a.run.app
    // https://apivisgateway-72pm3i3mfa-uc.a.run.app
    try {
      const response = await fetch(`https://apivisgateway-72pm3i3mfa-uc.a.run.app/user?id=` + this.props.id, {
        method: "GET",
        credentials: 'include',
        headers: new Headers({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        })
      })
      if (!response.ok) {
        // console.log('Resp:  ', response);
        throw Error(response.statusText);
      }
      const json = await response.json();
      //console.log('GET DATA', json.result);

      if ("oauth" in json.data) {
        let subdomainlowercase = "";
        if("subdomain" in json.data.oauth){
        let sub = json.data.oauth.subdomain
        subdomainlowercase = sub.toLowerCase();
        }
        //console.log(json.data.oauth.clientid)
        //console.log(json.data.payment_plan.meta.description)
        this.setState({
          clientid: json.data.oauth.clientid,
          clientsecret: json.data.oauth.clientsecret,
          subdomain: json.data.oauth.subdomain,
          subdomainlowercase: subdomainlowercase
        });
      }
      this.setState({
        description: json.data.payment_plan.meta.description
      })

      //console.log("Besk" , this.state.description)
      this.setState({ data: json, loading: false });
      /*if(!json.data.oauth){
        this.setState({ data: json, loading: false });
      }*/
    } catch (error) {
      console.log(error);
    }
  }

  async postToApi(values) {
    const token = await this.props.token;
    // console.log('Token: ',token)
    // export const addContact = async data => {
    try {
      const response = await fetch(`https://apivisgateway-72pm3i3mfa-uc.a.run.app/userput?id=` + this.props.id, {
        method: "PUT",
        //mode: "no-cors",
        cache: "no-cache",
        credentials: 'include',
        headers: new Headers({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }),
        body: JSON.stringify(values)

      });
      /*
      if (!response.ok) {
        // console.log('Resp:  ', response);
        // throw Error(response.statusText);
       }*/
      // response = 'ok';
      // let subdomain = values.subdomain;
      // let subdomainlowercase = subdomain.toLowerCase();

      if (response) {

        try {

          //console.log('Token:', token)
          const response = fetch(`https://nodered.apivis.io/create`, {

            method: "POST",
            //mode: "no-cors",
            cache: "no-cache",
            credentials: 'include',
            headers: new Headers({
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }),
            body: JSON.stringify({ "email": "", "subdomain": values.subdomain, "plan": this.state.description, "clientid": values.clientid, "clientsecret": values.clientsecret })
          });

          if (response) {
            console.log('Resp:  ', response);


            this.setState({ response: "Creating Server", loading: false });
          } else if (!response.ok) {
            this.setState({ response: "Failed to fetch", loading: false });
          }
          // const json = await response.json();
          //return json;
        } catch (error) {

          // console.log(error);
          this.setState({ response: "Something went wrong.", loading: false });
          return error
        }
        /* end */

        // console.log('Resp:  ', response);
        this.setState({ response: "Server Deployd!", loading: false });
      } else if (!response.ok) {
        this.setState({ response: "Failed to fetch", loading: false });
      }

      //console.log(values);
      const json = await response.json();
      return json;
    } catch (error) {

      //  console.log(error);
      this.setState({ response: "Failed to fetch.", loading: false });
      // return error;
    }


    //console.log('GET DATA', json.result);
    // build the server
    /*
var email = json.github_email;
 var subdomain = json.github_user;
 var plan = json.description;
 var clientid = json.clientid;
 var clientsecret = json.clientsecret;
    */
    /*try {

     //console.log('Token:', token)
     const response = fetch(`https://nodered.rodened.io/create`, {

       method: "POST",
       //mode: "no-cors",
       cache: "no-cache",
       credentials: 'include',
       headers: new Headers({
         "Content-Type": "application/json",
         "Authorization": `Bearer ${token}`
       }),
       body: JSON.stringify({"email": "", "subdomain": values.subdomain,"plan": this.state.description, "clientid": values.clientid, "clientsecret": values.clientsecret })
     });

     if (response) {
       console.log('Resp:  ', response);
       this.setState({ response: response.body, loading: false });
      }else if(!response.ok){
       this.setState({ response: "Failed to fetch", loading: false });
      }
     // const json = await response.json();
    //return json;
   } catch (error) {

    // console.log(error);
     this.setState({ response: "Something went wrong.", loading: false });
     return error
   }
   */



    // alert(JSON.stringify(response, null, 2));
    // return response.json();
    //};
  }

  // <Row className="container-fluid justify-content-center">

  goToPage(link) {
    this.setState(
      {
        link: link
      }
    )
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { clientid, clientsecret, subdomain, response } = this.state;
    //console.log(clientid)

    return (
      <div>
        <h1>GitHub OAuth Apps settings</h1>
        <p>Go to your GitHub accounts and select settings, developer and create an OAuth App. In the field Authorization callback ULR enter https://user_id.apivis.io/auth/strategy/callback. Replace user_id with your user name. Then enter your information here and your APIVIS server will be deployed with an OAuth secured editor. </p>
        <Formik


          initialValues={

            { clientid: clientid, clientsecret: clientsecret, subdomain: subdomain }

          }

          validate={values => {
            const errors = {};
            if (!values.clientid) {
              errors.clientid = 'Required';
            }

            if (!values.clientsecret) {
              errors.clientsecret = 'Required';
            }
            if (!values.subdomain) {
              errors.subdomain = 'Required';
            }

            if (values.subdomain) {
              let subdomain = values.subdomain;
              let subdomainlowercase = subdomain.toLowerCase();
              this.setState({ subdomainlowercase: subdomainlowercase })
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const response = this.postToApi(values);
            /*const response = await fetch(`http://crapserr.com/contacts`, {
             method: "POST",
             mode: "cors",
             cache: "no-cache",
             headers: {
               "Content-Type": "application/json"
             },
             body: JSON.stringify(data)
           });*/

            // console.log(response)
            //alert(JSON.stringify(response, null, 2));
            setSubmitting(false);

          }}
        >
          {({ isSubmitting }) => (

            <RSForm tag={FormikForm}>
              <p className="text-success">{response}</p>
              <FormGroup>
                <Label for="clientid">Client ID</Label>
                <Input tag={Field} type="text" name="clientid" placeholder="Client ID" />
                <ErrorMessage name="clientid" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <Label for="clientid">Client Secret</Label>
                <Input tag={Field} type="text" name="clientsecret" placeholder="Client Secret" />
                <ErrorMessage name="clientsecret" component="div" className="text-danger" />
              </FormGroup>
              <FormGroup>
                <Label for="subdomain">Your GitHub user name</Label>
                <Input tag={Field} type="text" name="subdomain" placeholder="Your GitHub user name" />
                <ErrorMessage name="subdomain" component="div" className="text-danger" />
                <p>Your URL for this app will be https://{this.state.subdomainlowercase}.apivis.io </p>
                <p>Your Authorization callback URL will be: https://{this.state.subdomainlowercase}.apivis.io/auth/strategy/callback</p>
              </FormGroup>
              <button type="submit" disabled={isSubmitting}>
                Save
          </button>
            </RSForm>
          )}
        </Formik>
      </div>

    )
  }

}
export default GitHubForm;
