const contentData = [
  {
    title: "Flow based editor in the cloud",
    link: "https://www.apivis.com",
    description:
      "A tool for wiring together online services, APIs and hardware devices."
  },
  {
    title: "Version control of your flows",
    link: "https://www.apivis.com",
    description:
      "Manage your flows as projects backed by a Git repository. The flows are version controlled and securely encrypted in the Git repository."
  },
  {
    title: "Build your own APIs",
    link: "https://www.apivis.com",
    description:
      "Connect a Google Dialogflow AI powered chatbot to Telegram, Slack, Messenger or other services through your REST APIs."
  },
  {
    title: "Setup a frontend for a machine learning model",
    link: "https://www.apivis.com",
    description:
      "Connect to existing AI services for sentiment analysis, text to speech, object detection or your own custom models using Python or javascript or R and make them available from a javascript application frontend."
  }
];

export default contentData;
