import React from "react";

import logo from "../assets/rodened_b2_300.png";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Quickly connect services, API and IoT hardware</h1>

    <p className="lead">
    Create your workflows without coding and integrate cloud services, email, chat, e-commerce, ai and more.
    </p>
  </div>
);

export default Hero;
