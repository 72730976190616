import React, { Component, Fragment } from "react";
//import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GitHubForm from "../components/GithubForm";
import { Button } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0 } from "../react-auth0-spa";

//const { user } = useAuth0();


/*
const postToApi = () => {
    setTheLink('Service');
  }*/
//const Edit = ({ match, location }) => {
    //class Edit extends Component {    
      /*  constructor(props) {
            super(props);
        
            this.state = { loading: true, data: undefined };
          
          }*/ 
     // async  componentDidMount () {
           // const { id } = this.props.match.params
       //    const { getTokenSilently } = useAuth0();
        //   const token = await getTokenSilently();
         //   this.setState({id: this.props.location.pathname, token: token})
            //console.log({this.state.id})
       // }
       
   // const { params: { id } } = match;
   //console.log(this.props.location.pathname) 
   const Edit = ({ match, location }) => { 
   const id = location.pathname;
   let splitString = id.split("/");
   console.log(splitString)
   
  let last = splitString.slice(-1)[0];
  const { loading, user } = useAuth0();
  const { getTokenSilently } = useAuth0();
  const token = getTokenSilently();
  if (loading || !user) {
    return <Loading />;
  }
   return (
   
        <Fragment>
            <GitHubForm id={last} token={token}/>
            <hr />       
        </Fragment>
        )
   
  }; 
   
   export default Edit;
